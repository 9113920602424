<template>

  <div class="warn">
    <div class="photos">
      <img src="/images/warning_photo/bad.jpg" alt=""> 
      <i class="icon-remove red"></i>
      <img src="/images/warning_photo/good.jpg" alt=""> 
      <i class="icon-check green"></i>
    </div>
    <div class="text">
      {{ $t('photo_video_manager_rule') }}
    </div>    
  </div>
  
</template>


<script>

export default {
  props:[     
  ],
  data() {
    return { 
    };
  }, 

  mounted () {
   
  },
  methods: {
  
  }
}
</script>
 

 
<style scoped>

.warn{    
  background-color: #fdd22535;
  color: #B58105;
  box-shadow: 0px 0px 0px 1px #b58105 inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
 
.warn .photos{
  position: relative;
    margin-right: 1rem;
    height: 34px;
    width: 80px;
    flex-shrink: 0; 
}
.warn .photos i{

    position: absolute;
    margin-left: -8px;
    margin-top: -8px;
    text-shadow: -1px 0px 1px white, 1px 0px 1px white, -1px 1px 1px white, 1px -1px 1px white;
}
.warn .photos i.green{
  color: green;
}
.warn .photos i.red{
  color: red;
}
.warn .photos img{
      width: 33px;
    border-radius: 5px;
    margin-right: 5px;
}
.warn .text{}



</style>