<template>

  <div class="param">



    <div v-if="label" class="label">
      {{ label }}
    </div>



    <div :class="'setup_ico ' + ico"></div>

    <div class="bx">
      <div class="vl">
        <input type="text" v-model="edited_num" @keyup="change" class="inp_fm">        
      </div>
      <div class="units">
        <template
          v-for="(opt,opt_key) in units"
          >          
          <div            
            @click="$emit('change_measure', opt.id)"
            :class="'unit unit_' + units.length + (opt.id == measure || units.length < 2 ? ' select ' : '')">            
            {{ opt.name ? $t(opt.name) : '' }}
          </div>
        </template>        
      </div>
    </div>

    <div class="hnt">
      {{ param }}
    </div>
     
  </div>
  
</template>


<script setup>

const { $convert } = useNuxtApp();

const emits = defineEmits(['update:num', 'change_measure']);

const props = defineProps({
  num: {      
    required: true,
  },
  ico: {
    type: String,
    required: true,
  },
  pattern: {
    type: String,
    default: 'float'
  },
  measure: {
    type: String, 
  },
  unit: {
    type: String, 
  },
  units: {
    type: Array,
    required: true,
  },
  label: {
    type: String, 
  }, 
  param: {
    type: String, 
  }, 
  numMin: {
    type: Number, 
  },
  numMax: {
    type: Number, 
  },
  isPresaved: {
    type: Boolean, 
    default: false,
  },
});

const edited_num = ref(props.num);
const edited_num_prev = ref('');

const patternComputed = computed(() => {
  if(props.pattern == 'text') return /^.*$/;
  if(props.pattern == 'number') return /^\d*$/;
  if(props.pattern == 'float') return /^\d*\.?\d*$/;
  return props.pattern;
});

const validateInput = () => {

  console.log(edited_num.value);

  if(edited_num?.value && typeof edited_num.value == 'string')
    edited_num.value = edited_num.value.replace(',','.')

  const regex = patternComputed.value;
  if (regex.test(edited_num.value)) {
    edited_num_prev.value = edited_num.value;
  } else {
    edited_num.value = edited_num_prev.value; 
  }

  if(props.numMin && Number(edited_num.value) < props.numMin) {
    edited_num.value = props.numMin;
  }else if(props.numMax && Number(edited_num.value) > props.numMax) {
    edited_num.value = props.numMax;
  }
}

const change = () => {     
  validateInput();
  // emits('update:num', $convert.legitimed(props.unit, props.measure, edited_num.value));
  emits('update:num',  edited_num.value);
}

onMounted(() => {   
  // if(!props.isPresaved)
    // edited_num.value = edited_num.value ? $convert.universal(props.unit, props.measure, edited_num.value) : null;
    edited_num.value = edited_num.value ? edited_num.value : null;
  // else
  //   edited_num.value = edited_num.value ? edited_num.value : null;
  // validateInput();
});

watch(() => props.num, (val) => {
  // if(!val.match(pattern.value)) {
  // val = val.replace(/[0-9]+[\.]?[0-9]*/g, '');
  // }
  edited_num.value = val ? val : null;
  // edited_num.value = val ? $convert.universal(props.unit, props.measure, val) : null;
});

watch(() => props.numMax, (val) => {
  // if(!val.match(pattern.value)) {
  // val = val.replace(/[0-9]+[\.]?[0-9]*/g, '');
  // }
  // edited_num.value = val ? val : null;
  validateInput();
  // edited_num.value = val ? $convert.universal(props.unit, props.measure, val) : null;
});

</script>
 


<style scoped>
  @import '@/assets/css/param.css';
</style>

<style scoped>
   
 



</style>