<template>


  <div 
    v-if="edited_item"
    :class="{'photo': true, 'is_loading': edited_item.is_loading, 'rotate90': rotate_value == 90, 'rotate180': rotate_value == 180, 'rotate270': rotate_value == 270}"
    @mouseleave="saveRotate"
    >

    
 
    <div class="remove_label" @click="remove"><i class="icon-remove"></i></div>

    <div v-if="item.is_video" class="video_play"></div>

    <SkPhotoEdit v-if="edited_item.is_loading" cl="gloading" :thumbnail="item?.thumbnail" />
    <img v-else data-not-lazy :src="edited_item.size_m">

    <div v-if="!edited_item.is_loading" class="label_rotate" title="Rotate" @click="rotate">
      <i class="icon-refresh"></i> Rotate
    </div>
    <div v-else-if="!edited_item.progress" class="label_progress">
      Waiting queue...
    </div>
    <div v-else-if="edited_item.progress < 100" class="label_progress">
      {{ edited_item.progress }}%
    </div>
    <div v-else class="label_progress">
      Resizing...
    </div>
    
    <textarea v-model="item.comment" placeholder="Your message here" class="name_form params_input" maxlength="80"></textarea>
    
  </div>
  
</template>


<script setup>

const props = defineProps({
  item: {
    type: Object
  }
});

const emits = defineEmits([
  'remove',
  'update:item'
]);

const rotate_value = ref(0);
const edited_item = ref(props.item);
const rotateTimeout = ref(null);

const rotate = () => {
  rotate_value.value = rotate_value.value + 90;
  if(rotate_value.value >= 360){
    rotate_value.value = 0;
  }

  if(rotate_value.value > 0){
    if(rotateTimeout.value) clearTimeout(rotateTimeout.value);
    rotateTimeout.value = setTimeout(() => {
      saveRotate();
    }, 2000);
  }
}

const remove = () => {
  emits('remove');
}

const change = () => {
  emits('update:item', edited_item.value);
}

const saveRotate = () => {
  if(rotateTimeout.value) clearTimeout(rotateTimeout.value);
  if(rotate_value.value){
    emits('rotate', edited_item.value, rotate_value.value);
    rotate_value.value = 0;
  }
}



watch(() => props.item, (new_item, oldId) => {
  edited_item.value = new_item;
}, { deep: true })


</script>
 

 
<style scoped>


.photo{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;

  flex-direction: column;
  width: 177px;
  background: var(--un-background-color-gray);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.32);
  position: relative;
  gap: 10px;
} 
.photo textarea{
    width: 100%;
    border-radius: 3px;
    border: 1px var(--un-element-color-gray) solid;
    padding: 10px;
    height: 80px;
    max-width: fit-content;
    margin-top: 6px;
    margin-bottom: -3px;
    resize: none;
}
.photo .loader_photo{
    width: 100%;
    height: 155px;
    position: relative;
    border-radius: 3px;
    z-index: 1;
}

.photo .label_progress,
.photo .label_rotate{
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 4px 5px;
  background-color: var(--un-background-color-gray);
  position: absolute;
  /* margin-top: -30px; */
  width: calc(100% - 60px);
  opacity: 0.5;
  cursor: pointer;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.photo:hover .label_rotate{
    opacity: 1;
}
.photo .label_progress{ 
  opacity: 1; 
}

.photo .gloading,
.photo img{
  width: 100%;    
  max-width: none;
  aspect-ratio: 1/1;
  border-radius: 3px;
  transition: transform 0.5s ease-in-out;
  object-fit: cover;

}
 


.photo .delete{
    width: 27px;
    height: 27px;
    color: white;
    background-color: black;
    opacity: 0;
    font-size: 13px;
    padding-left: 5px;
    padding-top: 4px;
    float: right;
    clear: both;
    cursor: pointer;
    margin-bottom: -23px;
    margin-right: 24px;
    transition: all 0.5s ease-in-out;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    right: -35px;
    top: -11px;
    border: 2px white solid;
}
.photo:hover .delete{
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.remove_label{
  background-color: #DB2828 !important;
  border-color: #DB2828 !important;
  color: #FFFFFF !important;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 25px;
  position: absolute;
  right: -9px;
  top: -9px;
}

.video_play{
  width: 56px;
  height: 56px;
  z-index: 1;
  position: absolute;
  background-image: url(/images/play_button.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 62px;
  left: calc(50% - 28px);
  top: calc(50% - 73px);
  pointer-events: none;
}
.rotate90 img{
  transform: rotate(90deg);
}
.rotate180 img{
  transform: rotate(180deg);
}
.rotate270 img{
  transform: rotate(270deg);
}

@container pb (max-width: 768px) {


  .dropzone_content .imgs{
    padding: 0px;
  }

  .photo{
    width: 100%; 
    flex-direction: row;
  }

  .photo .label_progress,
  .photo .label_rotate{
    position: absolute;
    left: 58px;
    opacity: 1;
    width: 110px;
    margin-left: 0px;
    height: 28px;
    top: 126px;
    background-color:  var(--un-background-color-gray);
  }

  .photo .loader_photo{
    width: 50px;
    height: 50px;
    float: left;
    position: relative;
    border-radius: 3px;
  } 

  .photo .gloading,
  .photo img {
    width: 100px;
    max-width: none;
    aprect-ratio: 1/1;
    border-radius: 3px;
    flex-shrink: 0;
    margin-bottom: 20px;
  } 

  .photo .ui.floating.label{
    margin-left: -25px!important;
    margin-top: 12px!important;
    font-size: 1.1rem;
    display: block;
  }

  .photo textarea{
    margin-top: 0px;
    width: calc(100% - 115px);
    max-width: none;
    height: 129px;
  }

  .video_play{
    width: 30px;
    height: 30px;
    background-size: contain;
    left: 45px;
    top: 45px;
  }
 
}



</style>